<template>
  <img v-on="$listeners">
</template>
<script>

import {getBrandKey, getEnv} from '@/utils/env.js';
export default {
  name: 'BaseIllustration',
  computed: {
    getSrc: function() {
      const partnerName = getBrandKey();
      const assetsUrl = getEnv('VUE_APP_ASSETS');
      return assetsUrl + '/'+partnerName+'/'+this.src;
    },
    getProps : function() {
      // eslint-disable-next-line no-unused-vars
      const {src, ...remainingProps} = this.$props;
      return remainingProps;
    }
  }
}
</script>
