<template>
  <div id="loader" className="init-loader">
    <BrandImg
      class="init-loader__logo"
      src="/solid/logos/tranzactcard_green.svg"
      alt="Logo" />
    <p className="init-loader__text">
      {{ statusText }}
    </p>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import BrandImg from '@/components/BrandImg.vue';
import loginMixins from '../mixins/login';
import Vue from 'vue';
import auth0 from '@/plugins/auth0';
import {setEnv} from '@/utils/env';

export default {
  mixins: [loginMixins],
  data() {
    return {
      statusText: 'Verifing details...'
    }
  },
  created() {
    const env = window.sessionStorage.getItem('selectedEnv')
    setEnv(env);
    (async () => {
      try {
        Vue.use(auth0);
        await Vue.getAuth0Instance()

        await this.handleRedirectCallback();

        const {accessToken} = await this.checkSession();
        this.verify(accessToken, undefined, (statusText) => {
          this.statusText = statusText;
        }).catch((e) => {
          console.log('e', e)
          this.apiErrorMessage(e.errorDescription || e.description || e.error || 'Something went wrong!!');
          this.$router.push('/welcome');
        });
      } catch (e) {
        console.log(e)
        this.$router.push('/welcome');
      }
    })()
  },
  methods: {
    ...mapActions('user', ['checkSession', 'handleRedirectCallback']),
  },
  components: {BrandImg}
}
</script>

<style lang="scss">
.init-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__logo {
    width: 100px;
    animation: translateY20 1s infinite alternate linear;
  }

  &__text {
    padding-top: 21px;
    font-weight: 700;
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
