import Admin from './index.vue';

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'Admin Dashboard',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () =>
          import(
            /* webpackChunkName: "AccountList" */ './views/Admin.vue'
          )
      }
    ]
  }
];

export default routes;
