import { updateBrand } from '@/utils/general';
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('user', ['getPersonDetails', 'getProgramDetails'])
  },
  methods: {
    ...mapActions('user', ['logout', 'getProgramConfig', 'verifyToken', 'checkSession', 'getPerson', 'getPersonFromAuth0']),
    ...mapMutations('user', ['setUserDetails', 'setUserAuthToken']),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    ...mapMutations('account', ['setSelectedAccount']),
    ...mapActions('account', ['listAllAccounts']),
    ...mapMutations('account', ['updateCreateAccountData']),
    loginSuccess(callback = null) {
      const loader = this.showLoader();
      /** @type {import('@/modules/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;

      this.getProgramConfig(person.programId)
        .then(() => {
          setTimeout(() => {
            updateBrand();
            this.setSelectedAccount(null);
            if(callback && typeof callback === 'function') {
              callback();
            } else {
              this.kycStatusFlow();
            }
          }, 0);

          this.applyProgramConfigs();
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    applyProgramConfigs() {
      /** @type {import('@/modules/user/store/state').userState['programDetails']} */
      const program = this.getProgramDetails;
      if (program && program.bank && program.bank.accountType) {
        const personalChecking = program.bank.accountType.personalChecking;
        const businessChecking = program.bank.accountType.businessChecking;
        Vue.prototype.$personalChecking = personalChecking;
        Vue.prototype.$businessChecking = businessChecking;
      }
    },
    kycStatusFlow() {
      /** @type {import('@/modules/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;
      if (person && person.kyc && person.kyc.status) {
        /** logic  @see {@link https://wise.atlassian.net/browse/WB-33} */

        if (person.firstName && person.lastName) {
          window.analytics && window.analytics.identify(person.id, {
            name: `${person.firstName} ${person.lastName}`,
            email: person.email
          });
        } else {
          window.analytics && window.analytics.identify(person.id);
        }

        //if (person && person.metadata == null)
        //{
        //  window.location.href = 'https://www.tranzactcard.com';
        //}

        switch (person.kyc.status) {
        case 'notStarted':
          // this.$router.push('/kyc/personDetails');
          this.$router.push('/user/loginSuccess');
          break;
        case 'submitted':
          this.$router.push('/kyc/kycStatus');
          break;
        case 'approved':
          this.getAccounts(null);
          break;
        case 'inReview':
          this.$router.push('/kyc/kycStatus');
          break;
        default:
          this.$router.push('/kyc/kycStatus');
        }
      }
    },
    getBusinessList() {
      const loader = this.showLoader();
      this.$store
        .dispatch('kyb/listAllBusiness')
        .then((r) => {
          if (r.total === 0) {
            this.$router.push('/kyc/kycStatus');
          } else {
            /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']}*/
            const businessList = r.data;
            this.setSelectedBusiness(businessList[0]);

            if (businessList[0] && businessList[0].kyb) {
              switch (businessList[0].kyb.status) {
              case 'approved':
                if (businessList[0].disclosureStatus === 'completed') {
                  //account page
                  setTimeout(() => {
                    const accounts = this.$store.getters['account/getAllAccount'];
                    if(accounts.length === 0) {
                      this.$router.push('/account/create');
                    } else {
                      this.$router.push('/account/list');
                    }
                  }, 0);
                } else {
                  this.$router.push('/kyb/businessStatus');
                }
                break;
              case 'notStarted':
                this.$router.push('/kyb/businessDetails');
                break;
              default:
                this.$router.push('/kyb/businessStatus');
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    /**
     * get accounts list
     * @param {string} businessId is optional, in case of personalChecking only
     */
    getAccounts(businessId) {
      const loader = this.showLoader();
      this.listAllAccounts(businessId)
        .then((data) => {
          if (data.length === 0) {
            if (this.$businessChecking && this.$personalChecking) {
              this.$router.push('/kyc/accountType');
            } else if (this.$businessChecking) {
              this.getBusinessList();
            } else {
              this.updateCreateAccountData({
                businessId: null,
                type: 'personalChecking'
              });
              this.$router.push('/account/setup');
            }
          } else {
            setTimeout(() => {
              //home page
              this.$router.push('/account/list');
            }, 0);
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    logoutUser() {
      const loader = this.showLoader();
      this.logout()
        .then(() => {
          this.goToWelcomeScreen();
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    verify(accessToken, idNumberLast4, cb = () => {}) {
      return new Promise((resolve, reject) => {
        cb('Verifing details...');
        this.getPersonFromAuth0({accessToken}).then((u) => {
          this.setUserDetails({
            phone: u.phone_number,
          })

          this.setUserAuthToken({accessToken})

          this.verifyToken({accessToken, idNumberLast4}) // verifing details...
            .then(({refreshRequired}) => {
              if(refreshRequired) {
                cb('Creating session...');
                this.checkSession() // creating session...
                  .then(({accessToken}) => {
                    this.verify(accessToken, idNumberLast4, cb);
                  }).catch((e) => {
                    console.log('error', e)
                    reject(e)
                  })
              } else {
                cb('Initializing the app...');
                this.getPerson().then(() => {
                  this.loginSuccess();
                })

              }
            }).catch((e) => {
              if(e.code === 'EC_NEW_DEVICE_LOGIN') {
                this.$router.push('/user/loginSSN');
                resolve()
              } else {
                reject(e)
              }
            })
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
};
