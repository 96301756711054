import User from './index.vue';
import LoginCallback from './views/LoginCallback.vue';

const routes = [
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: 'loginSSN',
        name: 'LoginSSN',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(/* webpackChunkName: "login-ssn" */ './views/LoginSSN.vue')
      },
      {
        path: 'loginSuccess',
        name: 'LoginSuccess',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "login-success" */ './views/LoginSuccess.vue'
          )
      },
      {
        path: 'loginNotRegistered',
        name: 'LoginNotRegistered',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "login-not-registered" */ './views/LoginNotRegistered.vue'
          )
      }
    ]
  },
  {
    path: 'login-callback',
    component: LoginCallback,
    meta: {
      layout: 'login'
    },
  },
];

export default routes;
