<template>
  <div>
    <div class="mini-account-selector">
      <div class="menu">
        <div class="left-menu">
          <div class="logo-sec">
            <div class="set-logo" />
          </div>
          <div class="pages-list">
            <ul>
              <li>
                <router-link to="/dashboard/home">{{ $t('side_menu_overview') }}</router-link>
              </li>
              <li v-if="isTransactionsEnabled">
                <router-link to="/transaction/list">{{ $t('side_menu_transactions') }}</router-link>
              </li>
              <li v-if="isCardsEnabled">
                <router-link to="/card/list">{{ $t('side_menu_cards') }}</router-link>
              </li>
              <li v-if="isTransferMoneyEnabled">
                <router-link to="/fund">{{ $t('transfer_money') }}</router-link>
              </li>
              <li v-if="isSendMoneyEnabled">
                <router-link to="/contact/list">{{ $t('send_money') }}</router-link>
              </li>
              <li v-if="isAllowedUser">
                <router-link to="/admin">{{ $t('admin') }}</router-link>
              </li>
            </ul>
            <div class="profile" @click="goToProfile">
              <div class="initialsInfoLight">
                <span>{{ shortName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="scroll">
        <ul
          class="acccounts"
          data-testid="mini-accounts-list">
          <li
            :class="{
              selected: getSelectedAccount && getSelectedAccount.id === account.id,
              account: true
            }"
            v-for="account in getGroupedAccounts"
            :key="'account-selector-' + account.id"
            @click="selectAccount(account)">
            <div class="account__img center">
              {{ account.label[0] }}
            </div>
            <div>
              <div class="account__label">
                {{ account.label }}
              </div>
              <div class="account__label">
                {{ account.availableBalance | currency }}
              </div>
            </div>
          </li>
          <li v-if="!this.checkIfExistPersonalCheckingAccount" class="account__add-account" @click="addAccount">
            <span class="icon-spend-small">
              <span class="path1" />
              <span class="path2" />
              <span class="path3" />
            </span>
            <p>{{ $t('selectAcc_screenTitle') }}</p>
          </li>
        </ul>
      </div>-->
      <div class="right">
        <div class="toggle">
          <el-switch
            v-model="toggleTheme"
            v-on:change="toggleDarkTheme"
            active-color="var(--branding)"
            inactive-color="var(--branding)" />
        </div>
        <el-tag type="warning" v-if="env.VUE_APP_ENV !== 'prod'">
          TEST Mode
        </el-tag>
      </div>
    </div>

    <Drawer class="default-drawer" :components="userProfileRoutes" :show.sync="showUserProfile" v-if="showUserProfile" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import {getEnv, getUIConfig, getBrandConfig} from '@/utils/env';
import Drawer from '@/components/Drawer.vue';

export default {
  components: {
    Drawer
  },
  computed: {
    ...mapGetters('account', ['getGroupedAccounts']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('user',['getPersonDetails']),
    env() {
      return getEnv();
    },
    checkIfExistPersonalCheckingAccount() {
      return this.getGroupedAccounts.some(account => account.type === 'personalChecking');
    },
    shortName() {
      let name = '';
      if(this.getPersonDetails.firstName) {
        name = this.getPersonDetails.firstName[0];
      }
      if(this.getPersonDetails.lastName) {
        name+=this.getPersonDetails.lastName[0];
      }
      return name;
    },
  },
  data() {
    return {
      isTransactionsEnabled:  getUIConfig('isTransactionsEnabled'),
      isCardsEnabled: getUIConfig('isCardsEnabled'),
      isTransferMoneyEnabled: getUIConfig('isTransferMoneyEnabled'),
      isSendMoneyEnabled: getUIConfig('isSendMoneyEnabled'),
      isAllowedUser: false,
      showUserProfile: false,
      toggleTheme: true,
      userProfileRoutes: [
        {
          name: 'UserProfile',
          default: true,
          component: () => import('@m/dashboard/views/UserProfile.vue')
        },
        {
          name: 'EditUserAddress',
          component: () => import('@m/dashboard/views/EditUserAddress.vue')
        }
      ]
    }
  },
  mounted() {
    this.checkAllowedUsers();

    const container = this.$el.querySelector('.scroll');
    const el = this.$el.querySelector('.selected.account');
    if(el){
      const rect = el.getBoundingClientRect()
      container.scrollTo(rect.left, rect.top)
    }
  },
  methods: {
    ...mapMutations('account', ['setSelectedAccount', 'setAddAccountBackPage']),
    selectAccount(a) {
      if (!this.getSelectedAccount || a.id !== this.getSelectedAccount.id) {
        this.setSelectedAccount(a);
        this.$emit('accountChanged', a);
      }
    },
    addAccount() {
      this.setAddAccountBackPage(this.$router.currentRoute.fullPath);
      this.$router.push('/account/create');
    },
    goToProfile() {
      window.analytics && window.analytics.track('homeUserDetails');
      this.showUserProfile = true;
    },
    toggleDarkTheme() {
      window.toggleTheme();
    },
    setTheme(theme) {
      window.setTheme(theme);
    },
    checkAllowedUsers() {
      this.isAllowedUser = !!getBrandConfig('adminUsers').includes(this.getPersonDetails.id);
    }
  }
};
</script>
<style lang="scss">
.mini-account-selector {
  background: var(--branding-sidebar);
  border-bottom: 1px solid var(--branding-sidebar-border);
  padding: 0 30px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 999;

  .menu {
    width: 100%;

    .left-menu {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .pages-list {
        ul {
          display: inline-block;
          border-right: 2px solid;
          padding: 5px 15px 5px 0 var(--branding-primary);

          li {
            display: inline;

            a {
              cursor: pointer;
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.5px;
              text-decoration: none;
              margin: 0 15px;
              color: var(--branding);

              &.router-link-active {
                color: var(--branding-primary);
              }
            }
          }
        }

        .profile {
          display: inline-block;
          padding-left: 20px;
        }
      }
      .logo-sec {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 190px;

        .logo {
          cursor: pointer;
          height: 22px;
        }
      }
    }
  }

  .scroll {
    overflow-x: auto; 
    height: 120px;
    width: 100%;
  }
  .acccounts {
    display: flex;
    text-align: center;
    align-items: center;
    background-color: var(--branding-sidebar);
    width: 100%;
    height:59px;
    &.has-scrollbar {
      overflow-x: scroll;
    }
    li {
      height: 105px;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--branding-sidebar);
    }
    .account {
      width: 230px;
      min-width: 230px;
      padding: 20px 6px 6px 6px;
      cursor: pointer;
      
      background-color: var(--branding-sidebar);
      &__img {
        background: var(--branding-primary);
        color: white;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        display: inline-flex;
        margin-right: 22px;
      }

      &.selected {
        border-bottom: 6px solid var(--branding-primary);
        color: var(--branding);
        opacity: 1;

        .account__img {
          background: var(--branding-primary);
          width: 38px;
          height: 38px;
          font-size: 18px;
        }
        .account__label {
          font-size: 16px;
          opacity: 1;
        }
      }

      &__label {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 3px;
        letter-spacing: 0.02em;
        max-width: 130px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        opacity: 0.6;
      }

      &__add-account {
        padding: 20px 6px 6px 6px;
        cursor: pointer;
        width: 200px;
        min-width: 200px;
        background-color: var(--branding-sidebar);
        .icon-spend-small, p {
          opacity: 0.6;
        }
        &:hover {
          .icon-spend-small, p {
            opacity: 1;
          }
        }
        .icon-spend-small {
          font-size: 36px;
          display: inline-block;
          margin-right: 22px;
          border: 1px dashed #141414;
          border-radius: 30px;
          .path1:before {
            opacity: 0;
          }
          .path2, .path3 {
            &:before {
              color: var(--branding);
            }
          }
        }

        p {
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
          font-weight: 500;
          transition: 0.2s;
        }
      }
    }
  }
  .right {
    background-color: var(--branding-sidebar);
    border-left: 1px solid var(--branding-sidebar-border);
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-left: 20px;
    height: 59px;

    .el-tag.el-tag--warning {
      margin-left: 15px;
      color: white;
      background-color: var(--orange2);
      padding: 8px 10px;
      font-size: 14px;
    }
  }
}
</style>
