<template>
  <dashboard-layout class="test" v-if="layout=='dashboard'">
    <router-view class="dashboard-router-view" />
  </dashboard-layout>
  <login-layout v-else-if="layout=='login'">
    <router-view />
  </login-layout>
  <ao-layout v-else-if="layout=='ao'">
    <router-view />
  </ao-layout>
</template>
<script>
import DashboardLayout from '@/layouts/Dashboard.vue';
import LoginLayout from '@/layouts/Login.vue';
import AoLayout from '@/layouts/AO.vue';
export default {
  components: { DashboardLayout, LoginLayout, AoLayout },
  name: 'Home',
  computed: {
    layout:function() {
      return this.$route.meta && this.$route.meta.layout || 'dashboard'
    }
  },
  created() {
    if(typeof this.$route.query.fsDealerID !== 'undefined')
    {
      window.sessionStorage.setItem('fsDealerID',this.$route.query.fsDealerID);
      this.$router.push('/user/login');
      return;
    }

    // user login check logic here
    if(this.$route.path !== '/welcome' && this.$route.path !== '/kyc/idv-callback' && this.$route.path !== '/login-callback' && this.$route.path !== '/user/loginSSN') {
      this.$router.push('/welcome');
    }

  }
};
</script>

<style lang="scss" scoped>
  .dashboard-router-view {
    width: 100%;
  }
</style>
