import Vue from 'vue';
/**
 * @typedef {import('./state').kycState} kycState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  mutations: {
    /**
     * set onfido details
     * @param {kycState} state
     * @param {kycState['onfidoDetails']} payload
     */
    setOnfidoDetails(state, payload) {
      state.onfidoDetails = { ...state.onfidoDetails, ...payload };
    },
    /**
     * set kyc data
     * @param {kycState} state
     * @param {kycState['kycData']} payload
     */
    setKycData(state, payload) {
      state.kycData = payload;
    },
    /**
     * set selected account type
     * @param {kycState} state
     * @param {string} payload
     */
    setSelectedAccountType(state, payload) {
      state.selectedAccountType = payload;
    }
  },
  actions: {
    /**
     * get onfido token
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} personId
     */
    fetchOnfidoToken({ commit }, personId) {
      return new Promise((resolve, reject) => {
        http
          .post(`/v1/person/${personId}/idv-token`, {
            referrer: 'https://solidfi.app/*'
          })
          .then(({ data }) => {
            commit('setOnfidoDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * submit onfido
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     * @param {string} payload.personId
     * @param {string} payload.params
     */
    idvSubmit({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/person/' + payload.personId + '/idv', payload.params)
          .then((res) => {
            /** @type {import('./state').kycState['onfidoDetails']['submitDetails']} */
            const data = res.data;
            commit('setOnfidoDetails', { submitDetails: data });
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * submit kyc
     * @param {Object} param0
     * @param {Function} param0._
     * @param {string} personId
     */
    submitKyc(_, personId) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/person/' + personId + '/kyc', { personId })
          .then((res) => {
            /** @type {import('./state').kycState['kycData']} */
            const data = res.data;
            // commit('setKycData', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch kyc
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} personId
     */
    fetchKyc({ commit }, personId) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/person/' + personId + '/kyc')
          .then((res) => {
            /** @type {import('./state').kycState['kycData']} */
            const data = res.data;
            commit('setKycData', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  },
  getters: {
    /**
     * get onfido token from state
     * @param {kycState} state
     * @returns {kycState['onfidoDetails']|null}
     */
    getOnfidoDetails(state) {
      if (state && state.onfidoDetails) {
        return state.onfidoDetails;
      }
      return null;
    },
    /**
     * get onfido token from state
     * @param {kycState} state
     * @returns {kycState['kycData']|null}
     */
    getKycData(state) {
      if (state && state.kycData && state.kycData.id) {
        return state.kycData;
      }
      return null;
    }
  }
};
