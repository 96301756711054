<template>
  <div>
    <div class="sidebar-backdrop"
         @click="hideSidebar"
         v-if="getIsSidebarVisible" />
    <el-aside
      v-if="getIsMobileLayout"
      datat-testid="sidebar"
      class="sidebar"
      style="width: 289px;">
      <div class="logo-sec">
        <div class="set-logo" />
      </div>

      <!-- <div class="account-sec" data-testid="account-sec">
        <p v-if="getSelectedAccount.type !== 'personalChecking'" class="account_name" @click="viewAll">
          {{ getSelectedBusinessName }} <span class="icon-Arrow-small-right" />
        </p>
        <p v-else class="account_name" @click="viewAll">
          {{ getPersonDetails.firstName + ' ' +getPersonDetails.lastName }} <span class="icon-Arrow-small-right" />
        </p>
        <div data-testid="account-sec-link" class="go-to" @click="viewAll">
          {{ $t('side_menu_viewAll') }} <span class="icon-arrow-right" />
        </div>
      </div> -->
          
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo" router>
        <el-menu-item index="/dashboard/home" @click="hideSidebar">
          <i class="iconMenu-overview" />
          <span>{{ $t('side_menu_overview') }}</span>
        </el-menu-item>
        <el-menu-item
          index="/transaction/list"
          @click="hideSidebar"
          v-if="isTransactionsEnabled">
          <i class="icon-transactions-new" />
          <span>{{ $t('side_menu_transactions') }}</span>
        </el-menu-item>
        <el-menu-item
          index="/card/list"
          @click="hideSidebar"
          v-if="isCardsEnabled">
          <i class="iconMenu-card" />
          <span>{{ $t('side_menu_cards') }}</span>
        </el-menu-item>
        <el-menu-item
          index="/fund"
          @click="hideSidebar"
          v-if="isTransferMoneyEnabled">
          <i class="iconMenu-transfer" />
          <span>{{ $t('transfer_money') }}</span>
        </el-menu-item>
        <el-menu-item
          index="/contact/list"
          @click="hideSidebar"
          v-if="isSendMoneyEnabled">
          <i class="iconMenu-send" />
          <span>{{ $t('send_money') }}</span>
        </el-menu-item>
      </el-menu>

      <div class="toggle">
        <el-switch
          v-model="toggleTheme"
          v-on:change="toggleDarkTheme"
          active-color="var(--branding)"
          inactive-color="var(--branding)" />
      </div>

      <div class="menu-footer"
           v-if="!getIsMobileLayout"
           @click="goToProfile">
        <div class="initialsInfoLight">
          <span>{{ shortName }}</span>
        </div>
        <div class="user-name">
          {{ getPersonDetails.firstName + ' ' +getPersonDetails.lastName }}
        </div>
        <span class="icon-arrow-right" />
      </div>
    </el-aside>
  
    <Drawer class="default-drawer" :components="userProfileRoutes" :show.sync="showUserProfile" v-if="showUserProfile" />
    <Drawer class="default-drawer" :components="businessDetailsRoutes" :show.sync="showBusinessDetails" v-if="showBusinessDetails" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Drawer from '@/components/Drawer.vue';
import { getUIConfig } from '@/utils/env';
export default {
  components: {
    Drawer
  },
  data() {
    return {
      isTransactionsEnabled:  getUIConfig('isTransactionsEnabled'),
      isCardsEnabled: getUIConfig('isCardsEnabled'),
      isTransferMoneyEnabled: getUIConfig('isTransferMoneyEnabled'),
      isSendMoneyEnabled: getUIConfig('isSendMoneyEnabled'),
      showUserProfile: false,
      showBusinessDetails: false,
      currentTheme: localStorage.getItem('current-theme'),
      toggleTheme: true,
      userProfileRoutes: [
        {
          name: 'UserProfile',
          default: true,
          component: () => import('@m/dashboard/views/UserProfile.vue')
        },
        {
          name: 'EditUserAddress',
          component: () => import('@m/dashboard/views/EditUserAddress.vue')
        }
      ],
      businessDetailsRoutes: [
        {
          name: 'BusinessDetails',
          default: true,
          component: () => import('@m/business/views/BusinessDetails.vue')
        },
        {
          name: 'EditBusinessAddress',
          component: () => import('@m/business/views/EditBusinessAddress.vue')
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['getIsSidebarVisible', 'getIsMobileLayout']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('account', ['isBusinessAccountType', 'getSelectedAccount']),
    shortName() {
      let name = '';
      if(this.getPersonDetails.firstName) {
        name = this.getPersonDetails.firstName[0]; 
      }
      if(this.getPersonDetails.lastName) {
        name+=this.getPersonDetails.lastName[0]; 
      }
      return name;
    },
    getSelectedBusinessName() {
      if(this.getSelectedBusiness != null) {
        return this.getSelectedBusiness.legalName;
      }
      return ''
    },
    setDrawerSize() {
      return this.getIsMobileLayout ? '100%': '30%';
    }
  },
  methods: {
    ...mapMutations(['setIsSidebarVisible']),
    viewAll() {
      window.analytics && window.analytics.track('homeViewAllAccounts');
      this.$router.push('/account/all');
      this.hideSidebar();
    }, 
    goToProfile() {
      window.analytics && window.analytics.track('homeUserDetails');
      this.showUserProfile = true;
    },
    goToBusiness() {
      window.analytics && window.analytics.track('homeEntityDetails');
      this.showBusinessDetails = true;
    },
    hideSidebar() {
      this.setIsSidebarVisible(false);
    },
    toggleDarkTheme() {
      window.toggleTheme();
    },
    setTheme(theme) {
      window.setTheme(theme);
    }
  }
}
</script>
<style lang="scss" scoped>
    .sidebar-backdrop {
      position: fixed;
      inset: 0;
      z-index: 999;
    }
    .sidebar {
        width: 289px;
        min-height: 670px;
        top: 0;
        left: 0;
        min-height: 100vh;
        background-color: var(--branding-sidebar);
        border-right: 1px solid var(--branding-sidebar-border);
        overflow-x: hidden;
        position: fixed;
         z-index: 1000;

        .set-logo {
            height: 20px!important;
        }

        .toggle {
            text-align: center;
            margin-top: 20px;
        }

        .logo-sec {
            min-height: 120px;
            border-bottom: 1px solid var(--branding-sidebar-border);
            padding: 28px 24px 48px 24px;
            img {
                max-width: 245px;
                max-height: 65px;
                min-height: 40px;
            }
        }
        .el-menu {
            border-right: none;
            background: var(--branding-sidebar);
        }
        .menu-footer {
            display: flex;
            align-items: center;
            height: 79px;
            width: 289px;
            border-top: 1px solid var(--branding-sidebar-border);
            padding: 12px;
            position: absolute;
            bottom: 0;
            @media (max-height: 670px) {
              position: relative;
            }
            .initialsInfoLight {
                color: var(--branding-primary);
            }
            .user-name {  
                font-size: 14px;
                line-height: 18px;
                padding: 15px 16px;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 16px;
            }
            .icon-arrow-right {
                font-size: 14px;
                color: var(--branding);
                position: absolute;
                right: 16px;
            }
        }
        .el-menu-item { 
            height: 51px;
            line-height: 51px;
            margin-bottom: 15px;
            font-size: 18px;
            &:hover, &:focus {
              background-color: var(--light-grey-3);
            }
            i {
                color: var(--branding);
            }
            span {
              color: var(--branding);
              margin-left: 15px;
            }
            &.is-active {
                background-color: var(--branding-primary);
                color: #FFF;
                i, span {
                    color: #FFF;
                }

            }
            
        }
        @media (max-width: 992px) {
          transform: translate3d(-100%, 0, 0);
          transition: transform 0.3s;

          .is-sidebarvisible & {
            transform: translate3d(0, 0, 0);
          }
        }
    }
    
    .account-sec {
        border-bottom: 1px solid var(--branding-sidebar-border);
        padding: 19px 32px 14px 24px;
        margin-bottom: 8px;
        
        .account_name {
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          
          span {
            padding-left: 24px;
            font-size: 14px;
            margin-left: auto;
            color: var(--branding);
          }
        }
    }
    .go-to {
        color: var(--branding);
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 15px;
        span {
            padding-left: 10px;
            font-size: 12px;
        }
    }

    
</style>
